<template>
  <div class="dashTrade">
    <header class="head bg-white w-100">
      <nav class="nav navbar d-flex px-3">
        <div class="left-side d-flex align-items-center gap-4 justify-content-between justify-content-md-start">
          <div class="logo">
            <a href="#">
              <img src="../assets/images/logo.png" alt="pan-trade" width="200">
            </a>
          </div>
          <div class="bars" id="closeBars"  @click="toggleSidebar">
            <img src="../assets/images/new/Hamburger.svg" alt="menu">
          </div>
        </div>
        <div class="menuItem d-none d-md-flex">
          <ul style="margin: 0; text-align: end;">
            <li>
              <div class="d-flex gap-2 align-items-center " >
                <img src="../assets/images/new/user.gif" alt="profile" width="28" />
                  <span class="user-text text-capitalize">{{ u_detail?.name }} </span> 
              </div>
              <!-- <div>  
                  <span class="user-text text-capitalize"></span>
              </div> -->
              </li>
              <li>
                <span style="color:#fd7e14"><strong>{{ u_detail?.unique_id }}</strong></span>
              </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: ["subscribe"],
  data() {
    return {
      u_detail:{},
      // SidebarIcon,
      // showSidebar: true
    };
  },

  mounted(){
        this.u_detail = this.$store.getters.getUserDetail;
    },
  methods:{
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
      this.$emit("toggle-subscribe", this.showSidebar);
    }
  }
};
</script>

<style scoped>
.dashTrade ul {
  list-style: none;
}
header.head {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

header.head nav.nav {
  width: 100%;
  display: block;
}

header.head nav.nav ul.nav-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header.head nav.nav ul.nav-list li a img {
    max-width: 25px;
}

header.head nav.nav ul.nav-list li a img.active {
    display: none;
}

header.head nav.nav ul.nav-list li  a.router-link-active.router-link-exact-active img.active {
    display: block;
}

header.head nav.nav ul.nav-list li  a.router-link-active.router-link-exact-active img.default {
    display: none;
}

.bars img{
  cursor: pointer;
}

.user-text{
  color:var(--grey2)
}



</style>
